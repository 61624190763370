import React, { useEffect, useState } from 'react';
import "../../assets/style/common/header.scss"
import { Navbar, Container, Nav, Image, Dropdown } from "react-bootstrap";
import logo from "../../assets/images/logo_vixco.svg";
import { Link } from 'react-router-dom';
import { useAccount, useConnect, useNetwork } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { disconnect } from '@wagmi/core'
import { BiPowerOff } from "react-icons/bi"
import { AiOutlineDownload, AiOutlineUpload } from "react-icons/ai"
import metamask from "../../assets/images/metamask.svg"
// import { BiPowerOff } from "react-icons/bi"
import { TabMenu, CopyToClip, popup } from './Utils';

const HeaderNew = () => {
    const { address } = useAccount();
    const [getNetwork, setNetwork] = useState();
    const [checkMetaMask, setCheckMetaMask] = useState("");
    const { chain, chains } = useNetwork()
    const { connect } = useConnect({
        connector: new InjectedConnector({ chains }),
        onError(error) {
            console.log(error);

            if (error.name === 'ResourceUnavailableRpcError') {
                popup('Switch to the Wallet and check', error.message);
            }
        },
        onMutate(args) {
            console.log('Mutate', args)
            if (args.connector.ready === true) {
                setCheckMetaMask(false)
            } else {
                setCheckMetaMask(true)
            }
        },
        onSettled(data, error) {
            console.log('Settled', { data, error })
        },
        onSuccess(data) {
            console.log('Success', data)
        },
    })
    const handleDisconnect = async () => {
        await disconnect()
    }
    useEffect(() => {
        if (chain?.id == 1 || chain?.id == 10 ||  chain?.id == 11155111 || chain?.id == 11155112) {
            setNetwork(chain.name)
            console.log(chain.name);
        }
        else {
            setNetwork("Unsupported Network")
        }
    }, [chain])
    return (
        <>
            <header className='app_header'>
                <Navbar expand="lg" variant="light">
                    <Container fluid>
                        <Link to="https://www.vixco.net" className='app_logo'>
                            <Image src={logo} alt="logo" fluid />
                        </Link>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >

                            </Nav>
                            <div className='right_header_wrap'>
                                <div className='header_btn_wrap'>
                                    <div className='btn disconnect_btn header_btn network_type'>{process.env.REACT_APP_NETWORK_TYPE}</div>
                                </div>
                                <div className='dropdown_wrap'>
                                    {checkMetaMask === true ? <a className='btn disconnect_btn header_btn' href='https://metamask.io/' target='_blank'><Image src={metamask} alt="metamask icn" fluid /> Please Install Metamask Wallet</a> : address ? <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="race_header_dropdown" >
                                            <div className='btn disconnect_btn header_btn'>{address.slice(0, 5)}...{address.slice(-5)}</div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className='user_profile_wrap'>
                                                <figure className='user_profile'>
                                                    <Image src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAsElEQVRYR2PslBX+z4AHHLiVgiLroDYHn3IGUtUzjjpg0IUAehyiRzipaYCQfow0MOoAuoeA5/dylHIAPY7RHWSt9Q5vOXD0mhDecgPdPMZRBwx4CJBaEOFNAFgkCZUbJNcFow4YDYHREBjwEKC0LkD3AMnlwKgDqB4CLYqpKO0BQvX5b5YgvOmQ9c86FHlC7QnGUQcMeAigN0jQIxg90aGnEUrVY7QJKTWQVAePOgAAXAoAZIiZ6M4AAAAASUVORK5CYII=' alt="Profile Icon" />
                                                </figure>
                                                <h4>{address.slice(0, 5)}...{address.slice(-5)}<CopyToClip value={address} /></h4>
                                            </div>
                                            <Dropdown.Item as={Link} to="/account/deposit"><AiOutlineDownload /> View Deposits</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/account/withdraw"><AiOutlineUpload /> View Withdrawals</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleDisconnect()}><BiPowerOff /> Disconnect</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> : <button onClick={() => connect()} className='btn disconnect_btn header_btn'>Connect Wallet</button>}
                                </div>
                            </div>
                            <div className="center_header_wrap"><TabMenu /></div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    )
}
export default HeaderNew