import React from 'react';
import "../../assets/style/common/footer.scss";
const Footer = () => {
  return (
    <>
      <footer className='app_footer'>
        <div className='footer_text_wrap'>
        &copy; 2024 VIXCO. All rights reserved.
        </div>
      </footer>
    </>
  )
}

export default Footer