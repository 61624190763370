import React, { useEffect, useState } from 'react';
import "../assets/style/deposit.scss";
import { Form, Image, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom"
import { Ethereum, Bnb, Dai, Usdt, Usdc, Btc } from 'react-web3-icons';
import { FaEthereum } from "react-icons/fa"
import ArrowsIcon from '../assets/images/arrows.svg'
import VixIcon from "../assets/images/vixco.svg"
import optimismIcon from "../assets/images/optimism.svg"
import Web3 from 'web3';
import { useAccount, useConnect, useNetwork, useBalance } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected';
import metamask from "../assets/images/metamask.svg"
import { TokenSymbol, SwitchNetwork, popup } from './common/Utils';

const optimismSDK = require("@eth-optimism/sdk")
const ethers = require("ethers")

const Withdraw = () => {
  const [ethValue, setEthValue] = useState("")
  const [txHash, setTxHash] = useState("")
  const [sendToken, setSendToken] = useState(process.env.REACT_APP_VIX_SYMBOL);
  const [errorInput, setErrorInput] = useState("")
  const [checkMetaMask, setCheckMetaMask] = useState("");
  const [loader, setLoader] = useState(null)
  const { address, isConnected } = useAccount()
  const { chain, chains } = useNetwork()

  useEffect(() => handleChange(ethValue), [sendToken]);

  const { connect } = useConnect({
    connector: new InjectedConnector({ chains }), onError(error) {
      console.log('Error', error)

      if (error.name === 'ResourceUnavailableRpcError') {
        popup('Switch to the Wallet and check', error.message);
      }
    },
    onMutate(args) {
      console.log('Mutate', args)
      if (args.connector.ready === true) {
        setCheckMetaMask(false)
      } else {
        setCheckMetaMask(true)
      }
    },
    onSettled(data, error) {
      console.log('Settled', { data, error })
    },
    onSuccess(data) {
      console.log('Success', data)
    },
  })
  const [metaMastError, setMetaMaskError] = useState("")
  //========================================================== BALANCES =======================================================================

  const { data } = useBalance({ address: address, chainId: Number(process.env.REACT_APP_L2_CHAIN_ID), watch: true })
  const dataVIX = useBalance({ address: address, token: process.env.REACT_APP_VIX_L2, watch: true, chainId: Number(process.env.REACT_APP_L2_CHAIN_ID) })

  useEffect(() => {
  }, [])

  ////========================================================== WITHDRAW =======================================================================

  const handleWithdraw = async () => {
    if (loader) {
      popup('Withdrawal is in progress.', 'To check the current status, please switch to the Wallet app and check.');
      return;
    }


    try {
      if (!ethValue) {
        setErrorInput("Please enter the amount");
      }
      else {
        if (!parseFloat(ethValue) > 0) {
          setErrorInput("Invalid Amount Entered!");
        } else {
          setErrorInput("");
          const l1Url = process.env.REACT_APP_L1_RPC_URL;
          const l1Provider = new ethers.providers.JsonRpcProvider(l1Url, "any");
          const l2Provider = new ethers.providers.Web3Provider(window.ethereum);
          const l1Signer = l1Provider.getSigner(address)
          const l2Signer = l2Provider.getSigner(address)
          const zeroAddr = "0x".padEnd(42, "0");
          const l1Contracts = {
            AddressManager: zeroAddr,
            StateCommitmentChain: zeroAddr,
            CanonicalTransactionChain: zeroAddr,
            BondManager: zeroAddr,
            L1CrossDomainMessenger: process.env.REACT_APP_PROXY_OVM_L1CROSSDOMAINMESSENGER,
            L1StandardBridge: process.env.REACT_APP_PROXY_OVM_L1STANDARDBRIDGE,
            OptimismPortal: process.env.REACT_APP_OPTIMISM_PORTAL_PROXY,
            L2OutputOracle: process.env.REACT_APP_L2_OUTPUTORACLE_PROXY,
          }
          const bridges = {
            Standard: {
              l1Bridge: l1Contracts.L1StandardBridge,
              l2Bridge: process.env.REACT_APP_L2_BRIDGE,
              Adapter: optimismSDK.StandardBridgeAdapter
            },
            ETH: {
              l1Bridge: l1Contracts.L1StandardBridge,
              l2Bridge: process.env.REACT_APP_L2_BRIDGE,
              Adapter: optimismSDK.ETHBridgeAdapter
            }
          }
          const crossChainMessenger = new optimismSDK.CrossChainMessenger({
            contracts: {
              l1: l1Contracts,
              l2: optimismSDK.DEFAULT_L2_CONTRACT_ADDRESSES,
            },
            //                        bridges: bridges,
            l1ChainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
            l2ChainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
            l1SignerOrProvider: l1Signer,
            l2SignerOrProvider: l2Signer,
            //                        bedrock: true,
          })
          //-------------------------------------------------------- SEND TOKEN VALUE -----------------------------------------------------------------

          var responseWithdraw;
          try {
            if (sendToken == process.env.REACT_APP_MAIN_SYMBOL) {
              const weiValue = parseInt(ethers.utils.parseEther(ethValue)._hex, 16)
              setLoader("WITHDRAWING...");
              const response = await crossChainMessenger.withdrawETH(weiValue.toString());
              const receipt = await response.wait();
              setTxHash(receipt.hash);
              console.log("txHash : ", txHash)
              if (receipt) {
                // bridge-api 에 Update 한다.
                const timestamp = (await l2Provider.getBlock(receipt.blockNumber)).timestamp;
                const item = {
                  chainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
                  transactionHash: receipt.transactionHash,
                  address: address,
                  blockNumber: receipt.blockNumber,
                  timestamp: timestamp,
                  amount: weiValue.toString(),
                  l1Token: '',
                  l2Token: '',
                  messageStatus: 6
                };
                await fetchWithdrawUpdate(item);

                setLoader(null);
                setEthValue("");
              }
            }
            if (sendToken == process.env.REACT_APP_VIX_SYMBOL) {
              var tokenValue = Web3.utils.toWei(ethValue, "ether")
              setLoader("WITHDRAWING...");
              var depositTxn2 = await crossChainMessenger.withdrawERC20(process.env.REACT_APP_VIX_L1, process.env.REACT_APP_VIX_L2, tokenValue);;
              var receiptToken = await depositTxn2.wait()
              responseWithdraw = depositTxn2;
              if (receiptToken) {
                // bridge-api 에 Update 한다.
                const timestamp = (await l2Provider.getBlock(receiptToken.blockNumber)).timestamp;
                const item = {
                  chainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
                  transactionHash: receiptToken.transactionHash,
                  address: address,
                  blockNumber: receiptToken.blockNumber,
                  timestamp: timestamp,
                  amount: tokenValue.toString(),
                  l1Token: process.env.REACT_APP_VIX_L1,
                  l2Token: process.env.REACT_APP_VIX_L2,
                  messageStatus: 4
                };
                await fetchWithdrawUpdate(item);

                setLoader(null);
                setEthValue("")
              }
            }
            //-------------------------------------------------------- SEND TOKEN VALUE END-----------------------------------------------------------------
            //            updateWallet()
          }
          catch (error) {
            setLoader(null);
            console.log({ error }, 98);
            popup('Withdrawal failed', error.message);
          }
        }
      }

    } catch (error) {
      console.log(error);
      popup('Withdrawal failed', error.message);
    }
  }
  ////========================================================== HANDLE CHANGE =======================================================================
  const [checkDisabled, setCheckDisabled] = useState(false)

  const handleChange = (value) => {
    if (sendToken == process.env.REACT_APP_MAIN_SYMBOL) {
      if (Number(data?.formatted) < Number(value)) {
        setCheckDisabled(true)
        setErrorInput("Insufficient balance.")
      } else {
        setCheckDisabled(false)
        setErrorInput("")
      }
      setEthValue(value)
    }
    if (sendToken == process.env.REACT_APP_VIX_SYMBOL) {
      if (Number(dataVIX.data?.formatted) < value) {
        setCheckDisabled(true)
        setErrorInput("Insufficient balance.")
      } else {
        setCheckDisabled(false)
        setErrorInput("")
      }
      setEthValue(value)
    }
  }

  // ============= For Format balance =========================
  const tokenBalance = () => {
    switch (sendToken) {
      case 'VIX':
      case 'tVIX':
        return dataVIX.data ? dataVIX.data.formatted : '0';
      case 'ETH':
      case 'tETH':
      default:
        return data ? data.formatted : '0';
    }
  }

  const fetchWithdrawUpdate = async (item) => {
    try {
      console.log("fetchWithdrawUpdate", item);
      const bridgeApiUrl = process.env.REACT_APP_BRIDGE_API_URL + "/withdraw";
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item)
      };
      const response = await fetch(bridgeApiUrl, requestOptions);
      console.log(response.status);
      if (!response.ok) {
        throw new Error("fetch Withdraw Update Fail!");
      }
      return;
    } catch (err) {
      console.log(err.message);
      popup('Withdrawal failed', err.message);
    }
  };

  return (
    <>
      <div className='bridge_wrap'>
        <section className='deposit_wrap'>
          <div className='title'>Withdrawal</div>
          <div className='description'>Transfer {sendToken} from {process.env.REACT_APP_L2_NETWORK_NAME} account to {process.env.REACT_APP_L1_NETWORK_NAME} network</div>
          {process.env.REACT_APP_NETWORK_TYPE === 'Mainnet' && <div className='bridge'>It usually takes about 7 days to complete a withdrawal.</div>}
          <div className='deposit_price_wrap'>
            <div className='deposit_price_title'>
              <h5>From <Image src={optimismIcon} alt="optimismIcon" fluid /> {process.env.REACT_APP_L2_NETWORK_NAME} network <SwitchNetwork L2 /></h5>
            </div>
            <div className='deposit_input_wrap'>
              <Form>
                <div>Amount</div>
                <div className='deposit_inner_input'>
                  <Form.Control type='number' name="eth_value" value={ethValue} onChange={e => handleChange(e.target.value)} placeholder="0" min="0" step="any" />
                  <Form.Select aria-label="Default select example" className='select_wrap' onChange={({ target }) => setSendToken(target.value)}>
                    <option>{process.env.REACT_APP_VIX_SYMBOL}</option>
                    <option>{process.env.REACT_APP_MAIN_SYMBOL}</option>
                  </Form.Select>
                </div>
                <div className='input_icn_wrap'>
                  {sendToken == "VIX" || sendToken == "tVIX" ? <span className='input_icn'><Image src={VixIcon} alt="VixIcon" fluid /></span> : <span className='input_icn'><Ethereum /></span>}
                </div>
              </Form>
              <div className='wallet_bal'>Balance: {Number(tokenBalance()).toFixed(5)} <TokenSymbol symbol={sendToken} L2 /></div>
              {errorInput && <small className='text-danger'>{errorInput}</small>}
            </div>
          </div>
          <div className='arrows'><Link to='/deposit'><Image src={ArrowsIcon} fluid /></Link></div>
          <div className='deposit_details_wrap'>
            <div className="deposit_details">
              <h5>To {process.env.REACT_APP_MAIN_SYMBOL == "ETH" ? <FaEthereum /> : <Bnb />} {process.env.REACT_APP_L1_NETWORK_NAME} network <SwitchNetwork /></h5>
            </div>
            <div className='deposit_inner_details'>
              {sendToken == "VIX" || sendToken == "tVIX" ? <span className='input_icn'><Image src={VixIcon} alt="VixIcon" fluid /></span> : <span className='input_icn'> <Ethereum /></span>}
              <p>You’ll receive: {ethValue ? ethValue : "0"} <TokenSymbol symbol={sendToken} /></p>
              <div>
              </div>
              {/* <span className='input_title'>ETH</span> */}
            </div>
          </div>
          <div className="deposit_btn_wrap">
            {
              checkMetaMask === true ?
                <a className='btn deposit_btn' href='https://metamask.io/' target='_blank'><Image src={metamask} alt="metamask icn" fluid /> Please Install Metamask Wallet</a> :
                !isConnected ?
                  <button className='btn deposit_btn' onClick={() => connect()}>CONNECT WALLET</button> :
                  chain.id !== Number(process.env.REACT_APP_L2_CHAIN_ID) ?
                    <SwitchNetwork className='btn deposit_btn' L2 onError={setMetaMaskError}>SWITCH TO {process.env.REACT_APP_L2_NETWORK_NAME}</SwitchNetwork> :
                    checkDisabled ?
                      <button className='btn deposit_btn'>WITHDRAWAL</button> :
                      <button className='btn deposit_btn' onClick={handleWithdraw}>
                        {
                          loader ?
                          <><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner><div className="loader_message">{loader}</div></> :
                            "WITHDRAWAL"
                        }
                      </button>
            }
          </div>
          {metaMastError && <small className="d-block text-danger text-center mt-2">{metaMastError}</small>}
        </section>
      </div>
    </>
  )
}

export default Withdraw