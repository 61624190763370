import React, { useState } from 'react'
import { createRoot } from 'react-dom/client';
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md"
import { Link, useLocation } from "react-router-dom"
import { useNetwork, useSwitchNetwork } from 'wagmi'
import '../../assets/style/common/popup.scss';

const TabMenu = () => {
    const location = useLocation()
    return (
        <>
            <ul>
                <li><Link to="/deposit" className={`${location.pathname == "/" ? "active" : location.pathname == "/deposit" ? "active" : ""}`}>Deposit</Link></li>
                <li><Link to="/withdraw" className={`${location.pathname == "/withdraw" ? "active" : ""}`}>Withdrawal</Link></li>
            </ul>
        </>
    )
}

export { TabMenu }

const TokenSymbol = ({ symbol, L2 }) => {
    var contract, info;

    if (L2) {
        switch (symbol) {
            case 'VIX':
            case 'tVIX':
                contract = process.env.REACT_APP_VIX_L2;
                break;
        }
    } else {
        switch (symbol) {
            case 'VIX':
            case 'tVIX':
                contract = process.env.REACT_APP_VIX_L1;
                break;
        }
    }

    if (contract) {
        info = <span className="token_info">({contract.slice(2, 6)}...{contract.slice(-4)})<CopyToClip value={contract} /></span>;
    }


    return <>{symbol} {info && info}</>;
}

export { TokenSymbol }

const CopyToClip = ({ value }) => {
    const [copied, setCopied] = useState(false);
    const renderTooltip = (props) => <Tooltip {...props}>Copied</Tooltip>;
    const onCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    };

    return <>
        <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} show={copied} overlay={renderTooltip}>
            <CopyToClipboard text={value} onCopy={onCopy}>
                <span style={{ cursor: 'pointer' }}><MdContentCopy /></span>
            </CopyToClipboard>
        </OverlayTrigger>
    </>;
}

export { CopyToClip }

const SwitchNetwork = ({ L2, onError, className, children }) => {
    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork({
        onError(error) {
            console.log('Error', error)
            const closePopup = () => close();
            const close = popup('Network cannot be switched', <>
                <div>1. If you canceled, please close the pop-up.</div>
                <div>2. The network may not have been added. If so, please add the network.</div>
                <div>3. It may not work because the Wallet app is already running. In this case, please switch to the app and check.</div>
            </>, <>
                <button onClick={() => { closePopup(); addNetwork(); }}>Add Network</button>
                <button className="cancel" onClick={closePopup}>Cancel</button>
            </>);
        },
        onMutate(args) {
            console.log('Mutate', args)
        },
        onSettled(data, error) {
            console.log('Settled', { data, error })
        },
        onSuccess(data) {
            console.log('Success', data)
        },
    });

    const addNetwork = () => {
        try {
            window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: L2 ? process.env.REACT_APP_L2_CHAIN_ID_WITH_HEX : process.env.REACT_APP_L1_CHAIN_ID_WITH_HEX,
                    rpcUrls: [L2 ? process.env.REACT_APP_L2_RPC_URL : process.env.REACT_APP_L1_RPC_URL],
                    chainName: L2 ? process.env.REACT_APP_L2_NETWORK_NAME : process.env.REACT_APP_L1_NETWORK_NAME,
                    nativeCurrency: {
                        name: process.env.REACT_APP_MAIN_SYMBOL,
                        symbol: process.env.REACT_APP_MAIN_SYMBOL,
                        decimals: 18
                    },
                    blockExplorerUrls: [L2 ? process.env.REACT_APP_L2_EXPLORER_URL : process.env.REACT_APP_L1_EXPLORER_URL]
                }]
            }).then((data) => {
                console.log('Add successfully');

                if (onError) {
                    onError('');
                }
            }).catch((err) => {
                if (err.code === -32002) {
                    console.log('Error: request stuck in pending state');
                    popup('Switch to the Wallet and check', 'Request stuck in pending state');

                    if (onError) {
                        onError('Request stuck in pending state');
                    }
                }
            });
        } catch (err) {
            console.log(err);
            popup('Network switch failed', err.message);
        }
    }

    const onClick = () => {
        switchNetwork(L2 ? process.env.REACT_APP_L2_CHAIN_ID : process.env.REACT_APP_L1_CHAIN_ID);
    }

    className ??= 'btn btn_switch';
    children ??= 'Switch';

    return chain ?
        chain.id === Number(L2 ? process.env.REACT_APP_L2_CHAIN_ID : process.env.REACT_APP_L1_CHAIN_ID) ? <></> : <button className={className} onClick={onClick}>{children}</button> :
        <></>;
}

export { SwitchNetwork }

// https://stackoverflow.com/questions/67008939/how-to-render-a-popup-by-calling-a-function?answertab=scoredesc#tab-top
const popup = (header, body, footer) => {
    const PopupContent = () => {
        return (
            <div className='modal open'>
                <section>
                    <header>
                        {header ?? 'Error'}
                        <button onClick={close}>&times;</button>
                    </header>
                    <main>{body}</main>
                    <footer>
                        {footer || <button onClick={close}>Close</button>}
                    </footer>
                </section>
            </div>
        );
    };

    const close = () => {
        root.unmount();
        node.remove();
    }

    const node = document.createElement("div");
    document.body.appendChild(node);
    const root = createRoot(node);
    root.render(<PopupContent />);
    return close;
};

export { popup }
